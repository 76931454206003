<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
      scrollable
    >
      <v-card>
        <v-card-title class="caption">
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="caption d-flex justify-center">
          <h3>{{isSend ? msgDialogSend : msgDialog}}</h3>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{$_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            color="primary"
            class="white--text"
            @click="deleteData"
          >
            {{$_strings.common.YES}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    itemSelected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      msgDialog: 'Anda yakin akan menghapus dokumen ini ?',
      msgDialogSend: 'Anda yakin akan mengirim semua dokumen ini ?',
      isSend: false,
    };
  },
  methods: {
    deleteData() {
      if (this.isSend) {
        this.$emit('savePod');
        this.dialog = false;
      } else {
        this.$emit('deleteDocument', this.itemSelected);
        this.dialog = false;
      }
    },
  },
};
</script>
