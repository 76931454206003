var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 ma-0",attrs:{"fluid":""}},[_c('FilterData',{attrs:{"filters":_vm.filters},on:{"fetchData":_vm.setFilterData}}),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"calculate-widths":"","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        },"item-class":_vm.setColorBackground},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.pickupDate",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.pickupDate)))]),_c('span',[_vm._v(_vm._s(_vm.timeFormat(item.pickupDate)))])]}},{key:"item.dropDate",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.dropDate)))]),_c('span',[_vm._v(_vm._s(_vm.timeFormat(item.dropDate)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.userAccess.canUpdate)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewDetail(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }