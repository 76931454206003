<template>
  <v-container class="pa-2 ma-0" fluid>
    <FilterData
      :filters="filters"
      @fetchData="setFilterData"
    />
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="items"
          calculate-widths
          class="body"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
          :item-class= "setColorBackground"
        >
          <template v-slot:[`item.pickupDate`]={item}>
            <p class="ma-0">{{dateFormat(item.pickupDate)}}</p>
            <span>{{timeFormat(item.pickupDate)}}</span>
          </template>
          <template v-slot:[`item.dropDate`]={item}>
            <p class="ma-0">{{dateFormat(item.dropDate)}}</p>
            <span>{{timeFormat(item.dropDate)}}</span>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="viewDetail(item)"
                  v-if="userAccess.canUpdate"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat,
  timeFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import FilterData from './FilterData.vue';

export default {
  name: 'list-pod-document',
  components: {
    FilterData,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.podDocument.NO_CARGO,
          value: 'cargoTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.PICKUP_DATE,
          value: 'pickupDate',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.DROP_DATE,
          value: 'dropDate',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.STATUS,
          value: 'latestStatus',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.DRIVER_NAME,
          value: 'companyDriverName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
      ],
      items: [],
      totalItems: 0,
      pagination: defaultPagination(),
      filters: {
        dropDate: '',
        cargoTitle: this.$route.query.cargoTitle ? `qlike(${this.$route.query.cargoTitle})` : '',
      },
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    dayjs,
    setColorBackground(item) {
      if (item.latestStatus === 'Dokumen Tidak Lengkap') {
        return 'yellow';
      }
    },
    setFilterData() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchData();
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const {
          page, itemsPerPage, sortDesc, sortBy,
        } = this.pagination;
        const { cargoTitle, dropDate } = this.filters;
        const filters = {
          page: page - 1,
          size: itemsPerPage,
          sort: handleSortBy({ sortBy, sortDesc }),
          dropDate,
        };
        if (cargoTitle) {
          filters.cargoTitle = cargoTitle;
        }
        const result = await this.$_services.podDocument.getAllList(skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    viewDetail(item) {
      this.$router.push({
        name: 'dokumen-pod-list-detail',
        params: {
          shipmentCargoId: item.id,
        },
        query: {
          cargoTitle: item.cargoTitle,
          dropDate: this.filters.dropDate,
        },
      });
    },
  },
};
</script>
