var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('span',[_c('b',[_vm._v("No Kargo: "+_vm._s(_vm.$route.query.cargoTitle)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.isLoadingLatestStatus)?_c('v-skeleton-loader',{attrs:{"type":"heading"}}):_c('span',[_c('b',[_vm._v("Status: "+_vm._s(_vm.statusOrder(_vm.latestStatus)))])])],1),((_vm.latestStatus && _vm.latestStatus.toLowerCase() === 'dokumen tidak lengkap'
        || _vm.latestStatus && _vm.latestStatus.toLowerCase() === 'belum kirim dokumen')
        && _vm.userAccess.canCreate)?_c('v-col',[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.showDialogUploadDocument(false)}}},[_vm._v(" "+_vm._s(_vm.$_strings.podDocument.UPLOAD_DOCUMENT)+" ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"items-per-page":100,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.documentType",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.listDocumentType.find(function (list) { return list.value === item.documentType; }) && _vm.listDocumentType.find(function (list) { return list.value === item.documentType; }).text || item.documentType))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.showDialogEditDocument(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.userAccess.canUpdate)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary","disabled":!((_vm.latestStatus === 'Dokumen Tidak Lengkap')
                  || (_vm.latestStatus === 'DOKUMEN_TIDAK_LENGKAP')
                  || (_vm.latestStatus === 'Belum Kirim Dokumen')
                  || (_vm.latestStatus === 'BELUM_KIRIM_DOKUMEN')
                  || (_vm.latestStatus === 'BARANG_DITERIMA_UTUH')
                  || item.isRevise)},on:{"click":function($event){return _vm.showDialogEditDocument(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}},{key:"item.isRevise",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isRevise ? 'Ya' : 'Tidak'))]),(item.isRevise)?_c('v-icon',{staticClass:"alert_icon ml-2",attrs:{"color":"red"}},[_vm._v(" mdi-alert-circle ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[(_vm.userAccess.canUpdate)?_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","loading":_vm.isLoadingLatestStatus,"disabled":_vm.items.length === 0 || !(
          _vm.latestStatus && _vm.latestStatus.toLowerCase() === 'dokumen tidak lengkap'
          || _vm.latestStatus && _vm.latestStatus.toLowerCase() === 'belum kirim dokumen'
          || _vm.latestStatus && _vm.latestStatus.toLowerCase() === 'barang diterima utuh')},on:{"click":function($event){return _vm.showDialogConfirm(true)}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SEND)+" ")]):_vm._e(),_c('v-btn',{staticClass:"float-right mr-3",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b',[_vm._v(_vm._s(_vm.$_strings.common.BACK))])])],1)],1),_c('DialogUploadDocument',{ref:"dialogUploadDocument",attrs:{"listDocumentType":_vm.listDocumentType,"listLocation":_vm.itemsLocation,"listShipments":_vm.itemsShipments,"podDocumentList":_vm.podDocumentList},on:{"fetchList":_vm.fetchList}}),_c('DialogCofirm',{ref:"dialogConfirm",attrs:{"itemSelected":_vm.itemSelected},on:{"deleteDocument":_vm.deleteManyDocument,"savePod":_vm.savePod}}),_c('DialogEditDocument',{ref:"dialogEditDocument",attrs:{"latestStatus":_vm.latestStatus,"listDocumentType":_vm.listDocumentType,"listLocation":_vm.itemsLocation,"listShipments":_vm.itemsShipments},on:{"fetchList":_vm.fetchList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }