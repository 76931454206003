<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="600"
    >
      <v-card min-height="200" :loading="isLoading">
        <v-card-title class="caption">
          <h2>{{ isEdit || isView ? $_strings.podDocument.VIEW_DOCUMENT : $_strings.podDocument.UPLOAD_DOCUMENT }}</h2>
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                  :disabled="isLoading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(item, index) in documentListItems"
              :key="index"
            >
              <v-expansion-panel-header class="grey lighten-4">
                <v-row
                  justify="space-between"
                  align="center"
                >
                  <v-col cols="auto">
                    <p>{{listDocumentType.find((list) => list.value === item.documentType) && listDocumentType.find((list) => list.value === item.documentType).text || item.documentType}} {{index + 1}}</p>
                  </v-col>
                  <v-col
                    v-if="
                      (latestStatus.toLowerCase() === 'belum kirim dokumen'
                        || latestStatus.toLowerCase() === 'dokumen tidak lengkap'
                        || latestStatus.toLowerCase() === 'barang diterima utuh')
                        && !isView
                    "
                    cols="auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          icon
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="isLoading"
                          @click.stop.prevent
                          @click="showDialogConfirm(item)"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$_strings.common.DELETE}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    {{$_strings.podDocument.DOCUMENT_NAME}}
                    <span class="error--text d-inline">
                      *
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.DOCUMENT_NAME)]"
                      v-model="item.name"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    {{$_strings.podDocument.NUMBER_ORDER}}
                    <span class="error--text d-inline">
                      *
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      dense
                      :disabled="isView"
                      outlined
                      v-model="item.shipmentId"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.NUMBER_ORDER)]"
                      :items="listShipments"
                      item-value="shipmentId"
                      item-text="shipmentName"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    {{$_strings.podDocument.DOCUMENT_TYPE}}
                    <span class="error--text d-inline">
                      *
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      dense
                      :disabled="isView"
                      outlined
                      v-model="item.documentType"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.DOCUMENT_TYPE)]"
                      :items="listDocumentType"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    {{$_strings.podDocument.LOKASI}}
                    <span class="error--text d-inline">
                      *
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      dense
                      :disabled="isView"
                      outlined
                      v-model="item.locationId"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.LOKASI)]"
                      :items="listLocation"
                      item-value="locationId"
                      item-text="locationName"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <span>
                      {{$_strings.podDocument.UPLOAD_DOCUMENT}}
                      <span class="error--text d-inline">
                        *
                      </span>
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-col>
                      <div class="box-image" v-if="item.imageUrl" >
                        <div v-if="!isView" class="box-mdi-close">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="removeImage(index)"
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                                :disabled="isView"
                              >
                                mdi-close
                              </v-icon>

                            </template>
                            <span>{{$_strings.podDocument.REMOVE_IMAGE}}</span>
                          </v-tooltip>
                        </div>
                        <a @click="openFile(item.fileType, item.imageUrl, index)">
                          <viewer-image
                            :ref="'viewer_image'+index"
                            :key="'viewer_image'+index"
                            :src="item.imageUrl"
                            class="d-none"
                          />
                          <v-img
                            v-if="item.imageUrl && item.fileType !== 'PDF'"
                            alt="image"
                            :src="item.imageUrl"
                            aspect-ratio="1"
                            height="200px"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <v-img
                            v-if="item.imageUrl && item.fileType === 'PDF'"
                            src="@/assets/images/pdf-file.png"
                            height="200px"
                          />
                        </a>
                      </div>
                      <a v-else @click="$refs['file-upload'+index][0].click()">
                        <div :ref="'box-upload'+index" class="box-upload border-valid">
                          <v-icon color="grey">mdi-plus</v-icon>
                        </div>
                      </a>
                      <span class="red--text caption ml-4" :ref="'msg-error-fileUpload'+ index"></span>
                      <!-- INPUT FILE NATIVE -->
                      <input
                        :id="'file-upload'+index"
                        :ref="'file-upload'+index"
                        type="file"
                        @change="e => readFile(e, index)"
                        style="display: none;"
                        accept=".jpg,.jpeg,.png,.pdf"
                        hide
                        required
                      />
                    </v-col>
                  </v-col>
                </v-row>
                <v-row v-if="item.notes">
                  <v-col cols="12">
                    <span>{{$_strings.podDocument.HISTORY_NOTE}}</span>
                    <br>
                    <v-textarea
                      outlined
                      dense
                      disabled
                      class="grey--text"
                      v-html="item.notes"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <v-row v-if="!isView" justify="end">
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      small
                      block
                      class="white--text"
                      @click="updateDocument(item)"
                      :disabled="isLoading || !(item.encodedImages && item.encodedImages[0].encodedImage)"
                    >
                      {{$_strings.common.UPDATE}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{isView ? $_strings.common.BACK : $_strings.common.CANCEL}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialog"
      :urlPdf="urlPdfSelected"
    />
    <DialogCofirm
      ref="dialogConfirm"
      :itemSelected="itemSelected"
      @deleteDocument="deleteDocument"
    />
  </v-row>
</template>

<script>
import DialogCofirm from './Cofirm.vue';

export default {
  components: {
    DialogCofirm,
  },
  props: {
    latestStatus: {
      type: String,
      default: () => '',
    },
    podDocumentList: {
      type: Array,
      default: () => [],
    },
    listDocumentType: {
      type: Array,
      default: () => [],
    },
    listLocation: {
      type: Array,
      default: () => [],
    },
    listShipments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isEdit: false,
      isView: false,
      dialogViewPdf: false,
      urlPdfSelected: '',
      isRefetchList: false,
      itemSelected: {},
      documentListItems: [],
    };
  },
  methods: {
    readFile(ev, index) {
      const file = ev.target.files[0];
      const fileSize = file.size;
      const fileType = file.name.split('.').pop().toLowerCase();
      // CHECK FILE TYPE ONLY JPG, JPEG, PDF, PNG
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf'];
      if (!fileTypeSupport.includes(fileType)) {
        return this.$dialog.notify.error('Format file tidak didukung');
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (fileSize > 1024000) {
          this.$dialog.notify.error('Ukuran file tidak boleh lebih dari 1MB.', { timeout: 5000 });
        } else {
          this.documentListItems[index].encodedImages[0].encodedImage = e.target.result;
          this.documentListItems[index].encodedImages[0].fileType = fileType === 'pdf' ? 'PDF' : 'IMAGE';
          this.documentListItems[index].imageUrl = e.target.result;
          this.documentListItems[index].fileType = fileType === 'pdf' ? 'PDF' : 'IMAGE';
        }
      };
      reader.onloadend = () => {
        ev.target.value = '';
      };
      reader.readAsDataURL(file);
    },
    async removeImage(index) {
      if (!this.documentListItems[index].encodedImages || this.documentListItems[index].encodedImages) {
        this.documentListItems[index].encodedImages = [
          {
            encodedImage: null,
            fileType: null,
          },
        ];
      }
      this.documentListItems[index].imageUrl = null;
      this.documentListItems[index].fileType = null;
    },
    showDialogConfirm(item) {
      this.itemSelected = item;
      this.$refs.dialogConfirm.dialog = true;
      this.$refs.dialogConfirm.isSend = false;
    },
    async deleteDocument(item) {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.isLoading = true;
        await this.$_services.podDocument.deletePod(shipmentCargoId, item.documentId);
        this.$dialog.notify.success('Berhasil menghapus dokumen');
        this.$emit('fetchList');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
    async updateDocument(item) {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.isLoading = true;
        await this.$_services.podDocument.updateImage(shipmentCargoId, item.documentId, item);
        this.$emit('fetchList');
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openFile(fileType, url, index) {
      const _fileType = fileType || '';
      const ext = (url && url.split('.').pop()) || '';
      const imageExtension = ['jpg', 'png', 'jpeg', 'image'];
      if (imageExtension.includes(ext.toLowerCase()) || imageExtension.includes(_fileType.toLowerCase())) {
        this.$refs[`viewer_image${index}`][0].$el.children[0].click();
        return;
      }
      if (_fileType.toLowerCase() === 'pdf' || ext.toLowerCase() === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = url;
        return;
      }
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-upload {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.box-upload.border-valid {
  border: 1px solid #ddd;
}
.box-upload.border-error {
  border: 1px solid rgb(255, 0, 0);
}
.box-image {
  position: relative;
  border: 1px solid #dddd;
}
.box-mdi-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
.history{
  border-radius: 4px;
  border: solid 0.8px rgba(144, 144, 144, 0.35);
  background-color: #efefef;
  padding: 5px;
}
</style>
