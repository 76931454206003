<template>
  <v-container class="pa-2 ma-0" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <span> <b>No Kargo: {{$route.query.cargoTitle}} </b></span>
      </v-col>
      <v-col cols="12" sm="4">
        <v-skeleton-loader type="heading" v-if="isLoadingLatestStatus"></v-skeleton-loader>
        <span v-else><b>Status: {{statusOrder(latestStatus)}}</b></span>
      </v-col>
      <v-col
        v-if="(latestStatus && latestStatus.toLowerCase() === 'dokumen tidak lengkap'
          || latestStatus && latestStatus.toLowerCase() === 'belum kirim dokumen')
          && userAccess.canCreate"
      >
        <v-btn
          color="primary"
          outlined
          @click="showDialogUploadDocument(false)"
          class="float-right"
        >
          {{$_strings.podDocument.UPLOAD_DOCUMENT}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="items"
          :items-per-page="100"
          calculate-widths
          hide-default-footer
          class="body"
        >
          <template v-slot:[`item.documentType`]="{item}">
            <span>{{listDocumentType.find((list) => list.value === item.documentType) && listDocumentType.find((list) => list.value === item.documentType).text || item.documentType}}</span>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  class="mr-2"
                  color="primary"
                  @click="showDialogEditDocument(item, true)"
                >
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </template>
              <span>Lihat Detail</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="userAccess.canUpdate"
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  class="mr-2"
                  color="primary"
                  @click="showDialogEditDocument(item)"
                  :disabled="!((latestStatus === 'Dokumen Tidak Lengkap')
                    || (latestStatus === 'DOKUMEN_TIDAK_LENGKAP')
                    || (latestStatus === 'Belum Kirim Dokumen')
                    || (latestStatus === 'BELUM_KIRIM_DOKUMEN')
                    || (latestStatus === 'BARANG_DITERIMA_UTUH')
                    || item.isRevise)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.isRevise`]="{item}">
            <span>{{item.isRevise ? 'Ya' : 'Tidak'}}</span>
            <v-icon v-if="item.isRevise" class="alert_icon ml-2" color="red">
              mdi-alert-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="userAccess.canUpdate"
          class="float-right"
          color="primary"
          :loading="isLoadingLatestStatus"
          @click="showDialogConfirm(true)"
          :disabled="items.length === 0 || !(
            latestStatus && latestStatus.toLowerCase() === 'dokumen tidak lengkap'
            || latestStatus && latestStatus.toLowerCase() === 'belum kirim dokumen'
            || latestStatus && latestStatus.toLowerCase() === 'barang diterima utuh')"
        >
          {{$_strings.common.SEND}}
        </v-btn>
        <v-btn
          class="float-right mr-3"
          @click="$router.go(-1)"
        >
          <b>{{$_strings.common.BACK}}</b>
        </v-btn>
      </v-col>
    </v-row>
    <DialogUploadDocument
      ref="dialogUploadDocument"
      :listDocumentType="listDocumentType"
      :listLocation="itemsLocation"
      :listShipments="itemsShipments"
      :podDocumentList="podDocumentList"
      @fetchList="fetchList"
    />
    <DialogCofirm
      ref="dialogConfirm"
      :itemSelected="itemSelected"
      @deleteDocument="deleteManyDocument"
      @savePod="savePod"
    />
    <DialogEditDocument
      :latestStatus="latestStatus"
      :listDocumentType="listDocumentType"
      :listLocation="itemsLocation"
      :listShipments="itemsShipments"
      @fetchList="fetchList"
      ref="dialogEditDocument"
    />
  </v-container>
</template>

<script>
import DialogUploadDocument from './Dialog/UploadDocument.vue';
import DialogEditDocument from './Dialog/EditDocument.vue';
import DialogCofirm from './Dialog/Cofirm.vue';
import { statusOrder } from '../../helper/commonHelpers';

export default {
  name: 'pod-multimoda-list',
  components: {
    DialogUploadDocument,
    DialogCofirm,
    DialogEditDocument,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.podDocument.DOCUMENT_TYPE,
          value: 'documentType',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.DOCUMENT_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.ACTION,
          value: 'action',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.podDocument.REVISE,
          value: 'isRevise',
          class: 'white--text primary text-capitalize',
        },
      ],
      podDocumentList: [],
      items: [],
      itemsLocation: [],
      itemsShipments: [],
      listDocumentType: [
        {
          text: 'Foto Muat Barang',
          value: 'FOTO_MUAT_BARANG',
        },
        {
          text: 'Foto Bongkar Barang',
          value: 'FOTO_BONGKAR_BARANG',
        },
        {
          text: 'Foto Surat Jalan',
          value: 'FOTO_SURAT_JALAN',
        },
        {
          text: 'Foto Surat Serah Terima',
          value: 'FOTO_SURAT_SERAH_TERIMA',
        },
        {
          text: 'Foto BASP',
          value: 'FOTO_BASP',
        },
        {
          text: 'Foto Bukti Basp',
          value: 'FOTO_BUKTI_BASP',
        },
        {
          text: 'Foto BAST',
          value: 'FOTO_BAST',
        },
        {
          text: 'Surat Perintah Kiriman',
          value: 'SURAT_PERINTAH_KIRIMAN',
        },
      ],
      itemSelected: {},
      isLoadingLatestStatus: false,
      latestStatus: null,
    };
  },
  mounted() {
    this.fetchLatestStatus();
    this.fetchList();
  },
  methods: {
    statusOrder,
    async fetchLatestStatus() {
      const { cargoTitle, dropDate } = this.$route.query;
      const filters = {
        page: 0,
        size: 1,
        dropDate,
        cargoTitle,
      };
      try {
        this.isLoadingLatestStatus = true;
        const result = await this.$_services.podDocument.getAllList(filters);
        if (result.data.contents.length) {
          this.latestStatus = result.data.contents[0].latestStatus;
        } else {
          this.latestStatus = 'Belum Kirim Dokumen';
        }
      } finally {
        this.isLoadingLatestStatus = false;
      }
    },
    async fetchList() {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.isLoading = true;
        const result = await this.$_services.podDocument.getList(shipmentCargoId);
        this.podDocumentList = result.data.shipmentDocumentPOD;
        this.items = [];
        const unRevisedItems = [];
        const reviseItems = [];
        result.data.shipmentDocumentPOD.forEach((doc) => {
          if (doc.isRevise) reviseItems.push(doc);
          if (!(unRevisedItems.find((item) => item.name === doc.name)) && !doc.isRevise) {
            unRevisedItems.push(doc);
          }
        });
        this.items = [...unRevisedItems, ...reviseItems];
        this.itemsLocation = result.data.locations;
        this.itemsShipments = result.data.shipments;
      } finally {
        this.isLoading = false;
      }
    },
    showDialogEditDocument(item = {}, isView = false) {
      let filterDocument = [];
      if (!item.isRevise) {
        filterDocument = this.podDocumentList.filter((doc) => doc.name === item.name && !doc.isRevise);
      } else {
        filterDocument.push(item);
      }
      this.$refs.dialogEditDocument.isView = isView;
      this.$refs.dialogEditDocument.documentListItems = JSON.parse(JSON.stringify(filterDocument));
      this.$refs.dialogEditDocument.dialog = true;
    },
    showDialogUploadDocument(isEdit = false, item = {}) {
      const encodedImages = this.podDocumentList.filter((i) => i.name === item.name);
      const imageUrl = [];
      encodedImages.forEach((x) => {
        if (x.fileType === 'IMAGE') {
          imageUrl.push({
            fileType: 'IMAGE',
            encodedImage: x.imageUrl,
          });
        } else {
          imageUrl.push({
            fileType: 'PDF',
            encodedImage: x.imageUrl,
          });
        }
      });
      this.$refs.dialogUploadDocument.dialog = true;
      this.$refs.dialogUploadDocument.isEdit = isEdit;
      this.$refs.dialogUploadDocument.item = { ...item, imageUrl };
    },
    showDialogDocument(isView = false, item = {}) {
      const encodedImages = this.podDocumentList.filter((i) => i.name === item.name);
      const imageUrl = [];
      encodedImages.forEach((x) => {
        if (x.fileType === 'IMAGE') {
          imageUrl.push({
            fileType: 'IMAGE',
            encodedImage: x.imageUrl,
          });
        } else {
          imageUrl.push({
            fileType: 'PDF',
            encodedImage: x.imageUrl,
          });
        }
      });
      this.$refs.dialogUploadDocument.dialog = true;
      this.$refs.dialogUploadDocument.isView = isView;
      this.$refs.dialogUploadDocument.item = { ...item, imageUrl };
    },
    showDialogConfirm(isSend = false, item) {
      this.$refs.dialogConfirm.dialog = true;
      this.$refs.dialogConfirm.isSend = isSend;
      this.itemSelected = item;
    },
    async deleteManyDocument(item) {
      const targetDataDeletion = this.podDocumentList.filter((data) => data.name === item.name);
      const { shipmentCargoId } = this.$route.params;
      try {
        this.$root.$loading.show();
        await Promise.all(targetDataDeletion.map((data) => (
          this.$_services.podDocument.deletePod(shipmentCargoId, data.documentId)
        )));
        this.$dialog.notify.success('Berhasil menghapus dokumen');
        this.items.splice(this.items.indexOf(item), 1);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async savePod() {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.$root.$loading.show();
        await this.$_services.podDocument.savePod(shipmentCargoId);
        this.fetchLatestStatus();
        this.$dialog.notify.success('Berhasil mengirim dokumen pod');
        this.$router.replace('/main/dokumen-pod');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.alert_icon {
  opacity: 0;
  animation: blink infinite 2s linear;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
