<template>
  <v-sheet elevation="1">
    <v-row
      dense
      align="center"
      class="pa-5"
    >
      <v-col cols="12" sm="8">
        <v-row dense>
          <v-col cols="6" sm="auto">
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="From Date"
                  :value="dayjs(dateFrom).format('DD-MM-YYYY')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                :max="dateEnd"
                @change="menuFromDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto" class="d-none d-sm-flex align-center">-</v-col>
          <v-col cols="6" sm="auto">
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  class="caption"
                  outlined
                  :value="dayjs(dateEnd).format('DD-MM-YYYY')"
                  placeholder="To Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="dateFrom"
                v-model="dateEnd"
                no-title
                @change="menuToDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="ml-md-auto"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :value="$route.query.cargoTitle || ''"
          @keyup.native.enter="fetchData($event)"
          :placeholder="$_strings.order.SEARCHNO"
        >
          <template v-slot:prepend-inner>
            <v-icon>
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-data',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const currentDate = dayjs().format('YYYY-MM-DD');
    this.filters.dropDate = `qbetween(${dayjs(this.$route.query.dateFrom || currentDate).subtract('7', 'days').format('YYYY-MM-DD')},${this.$route.query.dateEnd || currentDate})`;
    this.dateFrom = this.$route.query.dateFrom || dayjs(currentDate).subtract('7', 'days').format('YYYY-MM-DD');
    this.dateEnd = this.$route.query.dateEnd || currentDate;
  },
  data() {
    return {
      menuFromDate: false,
      dateFrom: '',
      menuToDate: false,
      dateEnd: '',
    };
  },
  watch: {
    dateFrom: function dateFrom(newVal, oldVal) {
      if (oldVal) {
        this.filters.dropDate = `qbetween(${dayjs(newVal).format('YYYY-MM-DD')},${this.dateEnd})`;
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            dateFrom: this.dateFrom,
          },
        });
        this.$emit('fetchData');
      }
    },
    dateEnd: function dateEnd(newVal, oldVal) {
      if (oldVal) {
        this.filters.dropDate = `qbetween(${this.dateFrom},${dayjs(newVal).format('YYYY-MM-DD')})`;
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            dateEnd: this.dateEnd,
          },
        });
        this.$emit('fetchData');
      }
    },
  },
  methods: {
    dayjs,
    fetchData(event) {
      this.filters.cargoTitle = `qlike(${event.target.value})`;
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          cargoTitle: event.target.value,
          page: 1,
        },
      });
      this.$emit('fetchData');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
