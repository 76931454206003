<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="600"
    >
      <v-card :loading="isLoading">
        <v-card-title class="caption">
          <h2>{{ isEdit || isView ? $_strings.podDocument.VIEW_DOCUMENT : $_strings.podDocument.UPLOAD_DOCUMENT }}</h2>
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                  :disabled="isLoading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row v-show="isEdit || isView">
              <v-col cols="11">
                <span class="float-right mt-5" required>{{$_strings.podDocument.REVISE}}
                  <span class="error--text d-inline">
                    *
                  </span>
                </span>
              </v-col>
              <v-col cols="1">
                <v-checkbox
                  v-model="form.isRevise"
                  @change="($event) => handleNotes($event, form)"
                  disabled
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="isEdit || isView">
              <v-col cols="12" class="pb-0">
                {{$_strings.podDocument.DOCUMENT_NAME}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.DOCUMENT_NAME)]"
                  v-model="form.documentName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.podDocument.NUMBER_ORDER}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  :disabled="isView"
                  outlined
                  v-model="form.shipmentId"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.NUMBER_ORDER)]"
                  :items="listShipments"
                  item-value="shipmentId"
                  item-text="shipmentName"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.podDocument.DOCUMENT_TYPE}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  :disabled="isView"
                  outlined
                  v-model="form.documentType"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.DOCUMENT_TYPE)]"
                  :items="listDocumentType"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.podDocument.LOKASI}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  :disabled="isView"
                  outlined
                  v-model="form.locationId"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.LOKASI)]"
                  :items="listLocation"
                  item-value="locationId"
                  item-text="locationName"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <span>
                  {{$_strings.podDocument.UPLOAD_DOCUMENT}}
                  <span class="error--text d-inline">
                    *
                  </span>
                </span>
              </v-col>
              <v-col cols="12">
                <v-col v-for="(subEncodedImages, index) in form.encodedImages" :key="index">
                  <div class="box-image" v-if="subEncodedImages.encodedImage" >
                    <div class="box-mdi-close">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="removeImage(index, subEncodedImages.encodedImage)"
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            :disabled="isView"
                          >
                            mdi-close-thick
                          </v-icon>

                        </template>
                        <span>{{$_strings.podDocument.REMOVE_IMAGE}}</span>
                      </v-tooltip>
                    </div>
                    <a @click="openFile(subEncodedImages)">
                      <v-img
                        v-if="subEncodedImages.encodedImage && subEncodedImages.fileType === 'IMAGE'"
                        alt="image"
                        :src="subEncodedImages.encodedImage"
                        height="200px"
                        contain
                      />
                      <v-img v-if="subEncodedImages.encodedImage && subEncodedImages.fileType === 'PDF'" src="@/assets/images/pdf-file.png" height="200px"/>
                    </a>
                  </div>
                  <a v-else @click="$refs['file-upload'+index][0].click()">
                    <div :ref="'box-upload'+index" class="box-upload border-valid">
                      <v-icon color="grey">mdi-plus</v-icon>
                    </div>
                  </a>
                  <span class="red--text caption ml-4" :ref="'msg-error-fileUpload'+ index"></span>
                  <!-- INPUT FILE NATIVE -->
                  <input
                    :id="'file-upload'+index"
                    :ref="'file-upload'+index"
                    type="file"
                    @change="e => readFile(e, index)"
                    style="display: none;"
                    accept=".jpg,.jpeg,.png,.pdf"
                    hide
                    required
                  />
                </v-col>
              </v-col>
            </v-row>
            <v-btn @click="addFile" color="primary" text small plain class="mb-5 float-end" :disabled="isView || this.form.encodedImages.length === 10">
              <v-icon>
                mdi-plus-circle
              </v-icon>
              <p class="mt-3">Tambah File</p>
            </v-btn>
            <v-row v-show="isEdit">
              <v-col cols="12" class="pb-0">
                {{$_strings.podDocument.NOTE}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :disabled="!form.isRevise"
                  dense
                  outlined
                  v-model="form.note"
                  :rules="form.isRevise ? [v => !!v || $_strings.messages.validation.REQUIRED($_strings.podDocument.NOTE)] : []"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row v-show="isEdit || isView">
              <v-col cols="12">
                <span class="grey--text">{{$_strings.podDocument.HISTORY_NOTE}}</span>
                <br>
                <v-textarea
                  outlined
                  dense
                  disabled
                  v-html="form.history"
                  class="history"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{isView ? $_strings.common.BACK : $_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isLoading || disableButtonSave"
            @click="submit"
          >
            {{ isEdit ? $_strings.common.UPDATE :  $_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-view-image
      :visible="dialogViewImage"
      @setDialog="setDialog"
      :imageUrl="imageSelected"
    />
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialog"
      :urlPdf="urlPdfSelected"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    podDocumentList: {
      type: Array,
      default: () => [],
    },
    listDocumentType: {
      type: Array,
      default: () => [],
    },
    listLocation: {
      type: Array,
      default: () => [],
    },
    listShipments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      form: {
        documentType: '',
        documentName: '',
        encodedImages: [
          {
            encodedImage: null,
            fileType: '',
          },
        ],
        locationId: '',
        shipmentId: '',
        isRevise: false,
        note: '',
        history: '',
      },
      item: {},
      isEdit: false,
      isView: false,
      disableButtonSave: false,
      dialogViewImage: false,
      imageSelected: '',
      dialogViewPdf: false,
      urlPdfSelected: '',
      isRefetchList: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.isRefetchList = false;
      if ((newVal && this.isEdit) || (newVal && this.isView)) {
        this.fetchDetail();
      }
      if (!newVal) {
        this.$refs.form.reset();
        this.form = {
          documentType: '',
          documentName: '',
          encodedImages: [
            {
              encodedImage: null,
              fileType: '',
            },
          ],
          locationId: '',
          isRevise: false,
          history: '',
        };
        this.item = {};
        this.isEdit = false;
        this.isView = false;
        this.disableButtonSave = false;
        this.$refs['file-upload0'][0].value = null;
        if (this.isRefetchList) {
          this.$emit('fetchList');
        }
      }
    },
  },
  methods: {
    addFile() {
      this.form.encodedImages.push({
        encodedImage: null,
        fileType: '',
      });
    },
    async fetchDetail() {
      this.form = {
        documentType: this.item.documentType,
        documentName: this.item.name,
        encodedImages: this.item.imageUrl,
        locationId: this.item.locationId,
        isRevise: this.item.isRevise,
        history: this.item.history,
        shipmentId: this.item.shipmentId,
      };
      this.disableButtonSave = true;
    },
    readFile(ev, index) {
      const file = ev.target.files[0];
      const fileSize = file.size;
      const fileType = file.name.split('.').pop().toLowerCase();
      // CHECK FILE TYPE ONLY JPG, JPEG, PDF, PNG
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf'];
      if (!fileTypeSupport.includes(fileType)) {
        return this.$dialog.notify.error('Format file tidak didukung');
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (fileSize > 1024000) {
          this.$dialog.notify.error('Ukuran file tidak boleh lebih dari 1MB.', { timeout: 5000 });
        } else {
          if (this.isEdit) {
            const newForm = {
              ...this.form,
              encodedImages: [
                {
                  encodedImage: e.target.result,
                  fileType: fileType === 'pdf' ? 'PDF' : 'IMAGE',
                },
              ],
            };
            this.uploadPodDocument(index, newForm);
            return;
          }
          this.form.encodedImages[index].encodedImage = e.target.result;
          this.form.encodedImages[index].fileType = fileType === 'pdf' ? 'PDF' : 'IMAGE';
          // disable button save if imageurl not base64
          this.disableButtonSave = false;
        }
      };
      reader.readAsDataURL(file);
    },
    async removeImage(index, encodedImage) {
      if (this.isEdit) {
        const targetItemDeletion = this.podDocumentList.find((data) => data.imageUrl === encodedImage);
        const { shipmentCargoId } = this.$route.params;
        try {
          this.isLoading = true;
          await this.$_services.podDocument.deletePod(shipmentCargoId, targetItemDeletion.documentId);
          this.form.encodedImages.splice(index, 1);
          this.isRefetchList = true;
        } finally {
          this.isLoading = false;
        }
        return;
      }
      this.$refs[`file-upload${index}`][0].value = null;
      this.form.encodedImages.splice(index, 1);
      if (this.form.encodedImages.length === 0) {
        this.form.encodedImages = [
          {
            encodedImage: null,
            fileType: '',
          },
        ];
      }
    },
    validateFileUpload() {
      if (!this.form.encodedImages[0].encodedImage) {
        this.$dialog.notify.error('Wajib Upload dokumen');
        return false;
      }
      return true;
    },
    async uploadPodDocument(index, form) {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.isLoading = true;
        await this.$_services.podDocument.uploadImage(shipmentCargoId, form);
        this.form.encodedImages[index].encodedImage = form.encodedImages[0].encodedImage;
        this.form.encodedImages[index].fileType = form.encodedImages[0].fileType;
        this.isRefetchList = true;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const { shipmentCargoId } = this.$route.params;
      const validFileUpload = this.validateFileUpload();
      const { name } = this.item;
      const { documentType, shipmentId, locationId } = this.form;
      const targetUpdateDeletion = this.podDocumentList.filter((data) => data.name === name);
      const newForm = targetUpdateDeletion.map((data) => ({
        ...data,
        documentType,
        shipmentId,
        locationId,
      }));
      if (this.$refs.form.validate() && validFileUpload) {
        try {
          this.isLoading = true;
          if (this.isEdit) {
            // await this.$_services.podDocument.updateImage(shipmentCargoId, documentId, this.form);
            await Promise.all(newForm.map((data) => (
              this.$_services.podDocument.updateImage(shipmentCargoId, data.documentId, data)
            )));
          } else {
            const newEncodedImages = [];
            this.form.encodedImages.forEach((encoded) => {
              if (encoded.encodedImage) newEncodedImages.push(encoded);
            });
            const form = {
              ...this.form,
              encodedImages: newEncodedImages,
            };
            await this.$_services.podDocument.uploadImage(shipmentCargoId, form);
          }
          this.$emit('fetchList');
          this.$dialog.notify.success('Berhasil');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleNotes(event, items) {
      if (!event) {
        // const index = this.form.isRevise(items);
        this.form.note = '';
      }
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openFile(item) {
      const fileType2 = item.fileType;
      if (fileType2 === 'IMAGE' || fileType2 === 'jpg' || fileType2 === 'jpeg') {
        this.dialogViewImage = true;
        this.imageSelected = item.encodedImage;
        return;
      }
      if (fileType2 === 'PDF') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.encodedImage;
        return;
      }
      window.open(item.encodedImage);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-upload {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}
.box-upload.border-valid {
  border: 1px solid #ddd;
}
.box-upload.border-error {
  border: 1px solid rgb(255, 0, 0);
}
.box-image {
  position: relative;
  border: 1px solid #dddd;
}
.box-mdi-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
.history{
  border-radius: 4px;
  border: solid 0.8px rgba(144, 144, 144, 0.35);
  background-color: #efefef;
  padding: 5px;
}
</style>
